import React from "react";
import moment from "moment";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import { useLazyFetchBacklinksQuery } from "../../../../backlinks/service";
import { Icon } from "antd";
//import { formatTimestamp } from "../../utils";

const formatDate = (dateStr) => {
    let inputFormat = moment(dateStr, "YYYY-MM-DDTHH:mm:ss");

    // Old reports have the date as unix timestamp
    if (!inputFormat.isValid()) {
        inputFormat = moment.unix(dateStr);
    }

    return inputFormat.format("MMM DD, YYYY");
};

const displayResult = (backlinks) => {
    if (backlinks && backlinks.length > 0) {
        return (
            <div className="description-meta">
                {backlinks.map((backlinkUrl, index) => (
                    <div key={index} style={{ marginBottom: "10px" }}>
                        <div className="flexbox align-center">
                            <span className="item-label">{`${formatDate(backlinkUrl.first_seen)}`}</span>&nbsp;
                            <a
                                href={backlinkUrl.url_from || backlinkUrl.source_url}
                                target="_blank"
                                rel="noopener noreferrer">
                                {backlinkUrl.url_from || backlinkUrl.source_url}
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    return null;
};

function ShowBacklinksButton({ domain }) {
    const [fetchBacklinks, { isLoading: isLoadingBacklinks, isSuccess: isBacklinksFetchSuccess, data: backlinks }] =
        useLazyFetchBacklinksQuery();

    const handleShowBacklinksClicked = () => {
        fetchBacklinks({ domain: domain, order_by: "first_seen", order_dir: "desc", offset: 0 }, true);
    };

    const showBacklinks = isBacklinksFetchSuccess && !!backlinks;

    return (
        <>
            {showBacklinks && displayResult(backlinks.data.slice(0, 10))}
            {!showBacklinks && (
                // <Button
                //     type="primary"
                //     style={{ marginTop: "12px", padding: "0 16px" }}
                //     loading={isLoadingBacklinks}
                //     onClick={handleShowBacklinksClicked}>
                //     Show backlinks
                // </Button>

                <div className="description-meta" style={{ marginTop: "8px" }}>
                    <span
                        className={`inline-modal-trigger fake-link full-list ${isLoadingBacklinks ? "loading" : ""}`}
                        onClick={handleShowBacklinksClicked}>
                        <Icon type={isLoadingBacklinks ? "loading" : "caret-right"} /> See results list
                    </span>
                </div>
            )}
        </>
    );
}

const BacklinksSection = ({ url, sectionKey, title, result, tooltip, relevance, passRate, competitorResults }) => {
    let renderResultComponent = () => null;

    if (Object.hasOwn(result, "backlinks_list")) {
        renderResultComponent = (result) => displayResult(result.backlinks_list);
    } else if (Object.hasOwn(result, "domain")) {
        renderResultComponent = (result) => <ShowBacklinksButton domain={result.domain} />;
    }

    const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

    return (
        <div id={sectionKey} className={`report-element ${result.status} backlinks`}>
            <SectionTitle title={title} status={result.status} passRate={passRate} />

            <div className="report-element-description">
                <SectionMessage
                    url={url}
                    status={result.status}
                    relevance={relevance}
                    message={result.message}
                    hasCompetitors={hasCompetitors}
                />
                {renderResultComponent(result)}
                <SectionCompetitorResults
                    results={competitorResults}
                    relevance={relevance}
                    render={renderResultComponent}
                />
            </div>

            <SectionTooltip tooltip={tooltip} />
        </div>
    );
};

export default BacklinksSection;
