import moment from 'moment';

import { BacklinksOverviewData, ChartDataPoint, IconProperties, TldDistribution, Backlink, ReferringDomain, TopAnchor, TopCountry } from './types';
import { backlinksOverviewSections, overviewChartDataTooltips } from './settings';

export function getBacklinksOverviewForInterval(overview: BacklinksOverviewData, startDateStr: string, endDateStr: string) {
    const startDateMoment = moment(startDateStr);
    const endDateMoment = moment(endDateStr);

    const overviewSettingsKeys = Object.values(backlinksOverviewSections).map((overviewSection) => overviewSection.key);

    let overviewForInterval: BacklinksOverviewData = { total_backlinks: [], referring_domains: [], domain_rank: [], follow_links: [], nofollow_links: [] };

    overviewSettingsKeys.forEach((key) => {
        overview[key].forEach((overviewDataPoint) => {
            const overviewMoment = moment(overviewDataPoint.c_at);

            if (overviewMoment.isSameOrAfter(startDateMoment) && overviewMoment.isSameOrBefore(endDateMoment)) {
                overviewForInterval[key].push(overviewDataPoint);
            }
        });
    });

    return overviewForInterval;
}

/**
 * @param overview the overview data
 * @param resourceType resource type from the overview data to get the trend of
 * @returns a properties object to be used in the Icon element
 */
export function getTrendProperties(overview: BacklinksOverviewData, resourceType: keyof BacklinksOverviewData): IconProperties {
    if (overview && resourceType in overview && overview[resourceType].length) {
        let count = overview[resourceType].length;

        if (count > 1) {
            let first = overview[resourceType][0]['value'];
            let last = overview[resourceType][count - 1]['value'];

            if (first < last) {
                return {
                    type: 'arrow-up',
                    style: { color: '#3f8600' },
                };
            } else if (first > last) {
                return {
                    type: 'arrow-down',
                    style: { color: '#cf1322' },
                };
            }
        }
    }
    return { type: 'swap', style: undefined };
}

/**
 * Will return the latest overview data for a given resource type
 * @param overview All overview data
 * @param resourceType A type of overview data
 * @returns a string representing the most recent data
 */
export function getMostRecentData(overview: BacklinksOverviewData, resourceType: keyof BacklinksOverviewData) {
    let value = 0;
    if (overview && resourceType in overview && overview[resourceType].length) {
        const resourceData = overview[resourceType];
        const lastData = resourceData[resourceData.length - 1];
        value = lastData.value;
    }

    return value.toString().concat(resourceType === 'domain_rank' ? '/1000' : '');
}

/**
 * @param overview All overview data
 * @param resourceType A type of overview data
 * @returns the chart data to be used for the specific resource type
 */
function getChartData(overview: BacklinksOverviewData, resourceType: keyof BacklinksOverviewData): ChartDataPoint[] {
    let overviewData: ChartDataPoint[] =
        overview && overview[resourceType] ? overview[resourceType].map((item) => ({ ...item, type: overviewChartDataTooltips[resourceType] })) : [];

    return overviewData;
}

export function getVisibleOverviewDataForDashboard(overview: BacklinksOverviewData): ChartDataPoint[] {
    let overviewData = getChartData(overview, 'total_backlinks');

    return overviewData;
}

export function getVisibleOverviewData(overview: BacklinksOverviewData, resourceType: keyof BacklinksOverviewData): ChartDataPoint[] {
    const overviewData = getChartData(overview, resourceType);

    if (overviewData.length === 0) {
        return [];
    }

    const pointsInReverse = [...overviewData];
    pointsInReverse.reverse();

    const weeklyPoints = [];
    weeklyPoints.unshift(pointsInReverse[0]);

    let nextDesiredMoment = pointMoment(pointsInReverse[0]).subtract(1, 'week');
    pointsInReverse.forEach((element) => {
        const elCreatedAt = pointMoment(element);
        // data from server comes every 24h +/- a couple of seconds/minutes
        if (elCreatedAt.isSameOrBefore(nextDesiredMoment, 'day')) {
            weeklyPoints.unshift(element);
            nextDesiredMoment = pointMoment(element).subtract(1, 'week');
        }
    });
    return weeklyPoints.slice(-4);
}

function pointMoment(point: ChartDataPoint) {
    return moment(point.c_at);
}

export const getResourceToExportAsJson = (data: Backlink[] | ReferringDomain[] | TldDistribution[] | TopAnchor[] | TopCountry[]) => {
    if (typeof data !== 'undefined') {
        return JSON.stringify(data);
    }
};

export const getResourceToExportAsCsv = (data: Backlink[] | ReferringDomain[] | TldDistribution[] | TopAnchor[] | TopCountry[]) => {
    if (typeof data !== 'undefined') {
        let csv = '';
        try {
            let columns = Object.keys(data[0]);
            columns.forEach((column) => {
                csv = `${csv}${column};`;
            });
            csv = csv.replace(/.$/, '\n');

            data.forEach((element) => {
                columns.forEach((column) => {
                    let value = element[column as keyof typeof element] || '';
                    csv = `${csv}${value};`;
                });
                csv = csv.replace(/.$/, '\n');
            });
        } catch (e) { }
        return csv;
    }
};

export function getYAxisMax(chartData: ChartDataPoint[]) {
    let values = chartData.map((item) => item['value']);

    let max = Math.max.apply(Math, values);
    let min = Math.min.apply(Math, values);
    let maxStr = max.toString();

    if (max > 100) {
        return max + 10 ** (maxStr.length - 2);
    }

    if (max - min === 1) {
        return max + 1;
    }

    return max + 5;
}

export function getYAxisMin(chartData: ChartDataPoint[]) {
    var values = chartData.map((item) => item['value']);

    var min = Math.min.apply(Math, values);
    var max = Math.max.apply(Math, values);
    var minStr = min.toString();

    if (min > 100) {
        return min - 10 ** (minStr.length - 2);
    }

    if (max - min === 1 && min - 1 > 0) {
        return min - 1;
    }

    if (min - 5 > 0) {
        return min - 5;
    }
    return 0;
}

export function tickFormatter(val: number) {
    if (val >= 1000000000) {
        val = val / 1000000000;
        return val.toFixed(1) + ' B';
    }
    if (val >= 1000000) {
        val = val / 1000000;
        return val.toFixed(1) + ' M';
    }
    if (val >= 100000) {
        val = val / 1000000;
        return val.toFixed(1) + ' M';
    }
    if (val >= 1000) {
        val = val / 1000;

        if (val < 10) {
            return val.toFixed(1) + ' K';
        }

        return val.toFixed(0) + ' K';
    }

    return val;
}
