import { Form, Input } from 'antd';
import React, { useState } from 'react';
import LastStepButtons from '../LastStepButtons';
import { extractKeywords } from '../../../utils/keywords';

const { TextArea } = Input;

type Props = {
    domain: string;
    keywords: string;
    maxKeywords: number;
    trackedKeywordsCount: number;
    onKeywordsChange: (newKeywords: string) => void;
    onPreviousStep: () => void;
    onFinishThisStep: () => void;
};

export default function SetKeywordsStep({ domain, keywords, maxKeywords, trackedKeywordsCount, onKeywordsChange, onPreviousStep, onFinishThisStep }: Props) {
    const [keywordsValidationError, setKeywordsValidationError] = useState<string>('');

    const handleKeywordsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const keywords = event.target.value;

        onKeywordsChange(keywords);
        setKeywordsValidationError('');
    };

    const validateKeywords = () => {
        const extractedKeywords = extractKeywords(keywords);

        if (extractedKeywords.length + trackedKeywordsCount <= maxKeywords) {
            onFinishThisStep();
        } else {
            setKeywordsValidationError('Keywords exceeded limit');
        }
    };

    const canAddKeywords = trackedKeywordsCount < maxKeywords;

    return (
        <div className="steps-content">
            <Form className="step-form">
                <Form.Item>
                    <div className="wizard-form-field">
                        <h3 className="field-title">Add your keywords for {domain}</h3>
                        {canAddKeywords && (
                            <p className="field-description">
                                Add up to {maxKeywords - trackedKeywordsCount} keywords to monitor your site's placement in search engine results
                            </p>
                        )}
                        {!canAddKeywords && <p className="field-description">You can not add keywords because your limit of {maxKeywords} was reached</p>}
                    </div>
                </Form.Item>
                <Form.Item
                    validateStatus={keywordsValidationError ? 'error' : 'validating'}
                    help={keywordsValidationError}
                >
                    <TextArea
                        value={keywords}
                        disabled={!canAddKeywords}
                        onChange={handleKeywordsChange}
                        placeholder="keyword1, keyword2, keyword3, etc"
                        autoSize={{ minRows: 5, maxRows: 25 }}
                        onKeyPress={(e) => e.stopPropagation()}
                        onKeyDown={(e) => e.stopPropagation()}
                        onKeyUp={(e) => e.stopPropagation()}
                    />
                </Form.Item>
            </Form>
            <div className="steps-action">
                <LastStepButtons
                    onPreviousClicked={onPreviousStep}
                    onSubmit={validateKeywords}
                />
            </div>
        </div>
    );
}
