import React, { useMemo } from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import { getUrl } from "../../utils";
import { Icon } from "antd";
import { useLazyFetchCompetitorDomainsQuery } from "../../../../competitorDomains/service";

const displayResult = (result) => {
    if (result.competitor_domains && result.competitor_domains.length > 0) {
        return (
            <div className="description-meta">
                <ul>
                    {result.competitor_domains.map((domain) => (
                        <li key={domain}>
                            <a href={getUrl(domain)} target="_blank" rel="noopener noreferrer">
                                {domain}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    if (result["competitors"] && result["competitors"].length > 0) {
        // Old reports have score instead of rank
        const unitOfValue = Object.hasOwn(result["competitors"][0], "rank") ? "rank" : "score";

        return (
            <div className="stats-table-container">
                <header>
                    <div className="col">Competitor domain</div>
                    <div className="col">Domain {unitOfValue}</div>
                </header>

                {result["competitors"].map((competitor, idx) => {
                    return (
                        <div className="row" key={idx}>
                            <div className="col">
                                <a href={getUrl(competitor["domain"])} target="_blank" rel="noopener noreferrer">
                                    {competitor["domain"]}
                                </a>
                            </div>
                            <div className="col">{competitor[unitOfValue]}</div>
                        </div>
                    );
                })}
            </div>
        );
    }

    if (result["competitors"] && result["competitors"].length === 0) {
        return (
            <div className="description-meta">
                <span>There are no indexed competitors for this domain!</span>
            </div>
        );
    }

    return null;
};

function ShowCompetitorDomainsButton({ domain, locationName, languageCode }) {
    const [
        fetchCompetitorDomains,
        { isLoading: isLoadingCompetitorDomains, isSuccess: isCompetitorDomainsFetchSuccess, data: competitorDomains }
    ] = useLazyFetchCompetitorDomainsQuery();

    const handleShowCompetitorDomainsClicked = () => {
        fetchCompetitorDomains({ domain: domain, locationName: locationName, languageCode: languageCode }, true);
    };

    const orderedCompetitorDomains = useMemo(() => {
        if (competitorDomains) {
            let orderedCompetitors = [...competitorDomains.competitors].sort((a, b) => b.rank - a.rank);

            return { ...competitorDomains, competitors: orderedCompetitors };
        } else {
            return undefined;
        }
    }, [competitorDomains]);

    const showCompetitorDomains = isCompetitorDomainsFetchSuccess && !!orderedCompetitorDomains;

    return (
        <>
            {showCompetitorDomains && displayResult(orderedCompetitorDomains)}
            {!showCompetitorDomains && (
                // <Button
                //     type="primary"
                //     style={{ marginTop: "12px", padding: "0 16px" }}
                //     loading={isLoadingCompetitorDomains}
                //     onClick={handleShowCompetitorDomainsClicked}>
                //     Show domains
                // </Button>
                <div className="description-meta" style={{ marginTop: "8px" }}>
                    <span
                        className={`inline-modal-trigger fake-link full-list ${
                            isLoadingCompetitorDomains ? "loading" : ""
                        }`}
                        onClick={handleShowCompetitorDomainsClicked}>
                        <Icon type={isLoadingCompetitorDomains ? "loading" : "caret-right"} /> See results list
                    </span>
                </div>
            )}
        </>
    );
}

const CompetitorDomainsSection = ({ url, sectionKey, title, result, tooltip, relevance, competitorResults }) => {
    let renderResultComponent = () => null;

    if (Object.hasOwn(result, "competitors") || Object.hasOwn(result, "competitor_domains")) {
        renderResultComponent = (result) => displayResult(result);
    } else if (Object.hasOwn(result, "location_name")) {
        renderResultComponent = (result) => (
            <ShowCompetitorDomainsButton
                domain={result.domain}
                locationName={result.location_name}
                languageCode={result.language_code}
            />
        );
    }

    const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

    return (
        <div id={sectionKey} className="report-element default">
            <SectionTitle title={title} />

            <div className="report-element-description">
                <SectionMessage
                    url={url}
                    status={result.status}
                    relevance={relevance}
                    message={result.message}
                    hasCompetitors={hasCompetitors}
                />
                {renderResultComponent(result)}
                <SectionCompetitorResults
                    results={competitorResults}
                    relevance={relevance}
                    render={renderResultComponent}
                    hideMessage={true}
                />
            </div>

            <SectionTooltip tooltip={tooltip} />
        </div>
    );
};

export default CompetitorDomainsSection;
