import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import HowToFix from "../../shared/HowToFix/HowToFix";

const passedContent = (robots_txt_url) => (
  <div className="description-meta">
    <span className="single-result">
      <a href={robots_txt_url} target="_blank" rel="noopener noreferrer">
        {robots_txt_url}
      </a>
    </span>
  </div>
);

const displayResult = (title, result) => {
  if (result.status === "passed" && result.robots_txt_url) {
    return passedContent(result.robots_txt_url);
  }

  if (result.status !== "passed") {
    return <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />;
  }

  return null;
};

const displayCompetitorResult = (result) => {
  if (result.robots_txt_url) {
    return passedContent(result.robots_txt_url);
  }
  return null;
};

const RobotsTxtSection = ({ url, sectionKey, title, result, tooltip, relevance, passRate, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;
  const passedResultMessage = `This website is using a robots.txt file.`;
  const updatedResultMessage = result.status === "passed" ? passedResultMessage : result.message;
  let updatedCompetitorResults = null;

  // TODO: fix message change to backend instead of client
  if (hasCompetitors) {
    updatedCompetitorResults = competitorResults.map((competitor) => {
      if (competitor.result.status === "passed") {
        competitor.result = {...competitor.result, message: passedResultMessage};
      }
      return competitor;
    });
  }

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={updatedResultMessage}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults
          results={updatedCompetitorResults}
          relevance={relevance}
          render={displayCompetitorResult}
        />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default RobotsTxtSection;
