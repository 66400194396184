import { createApi } from '@reduxjs/toolkit/query/react';
import { SSC_SEM_API_URL } from '../settings/config';
import { authRequest } from '../store/authorizedRequests';
import { FetchCompetitorDomainsParams, FetchCompetitorDomainsResponse } from './types';

const competitorDomainsBaseUrl = `${SSC_SEM_API_URL}/dfs/competitors`;

const RESOURCE_REFETCH_TIME = 7200;

export const competitorDomainsApi = createApi({
    reducerPath: 'api.competitorDomains',

    keepUnusedDataFor: RESOURCE_REFETCH_TIME,

    baseQuery: async (args, api, extraOptions) => {
        const { url, method, body, signal } = args;
        const { dispatch, getState } = api;

        try {
            const data = await authRequest(competitorDomainsBaseUrl.concat(url), dispatch, getState, {
                method: method,
                body: JSON.stringify(body),
                signal: signal,
            });

            return { data };
        } catch (error) {
            return { error };
        }
    },

    endpoints: (builder) => ({
        fetchCompetitorDomains: builder.query<FetchCompetitorDomainsResponse, FetchCompetitorDomainsParams>({
            query: (args) => {
                const { domain, locationName, languageCode } = args;

                return {
                    method: 'GET',
                    url: `?domain=${domain}&location_name=${locationName}&language_code=${languageCode}`,
                };
            },
        }),
    }),
});

export const { useLazyFetchCompetitorDomainsQuery } = competitorDomainsApi;
