import iamReducer from './store/iam/reducer';
import analysisReducer from './store/analysis/reducer';
import trackedWebsitesReducer from './store/tracked_websites/reducer';
import notificationsReducer from './store/notifications/reducer';
import loadingTimeReducer from './store/loading_time/reducer';
import monitorLocationsReducer from './store/monitor_locations/reducer';
import outageReducer from './store/outage/reducer';
import whiteLabelReducer from './store/white_label/reducer';
import salesReducer from './store/sales/reducer';
import trackersReducer from './store/trackers/reducer';

import { trackedWebsitesApi, locationsAndLanguagesApi } from './trackedWebsites/service';
import { backlinksApi } from './backlinks/service';
import { topKeywordsApi } from './topKeywords/service';
import { keywordResearchApi } from './keywordResearch/service';
import { trackedKeywordsApi } from './trackedKeywords/service';
import { brokenLinksApi } from './brokenLinks/service';
import { sitemapApi } from './sitemap/service';
import { siteSpeedApi } from './siteSpeed/service';
import { siteOutageApi } from './outage/service';
import { competitorDomainsApi } from './competitorDomains/service';

import { combineReducers, configureStore } from '@reduxjs/toolkit';
// @ts-ignore
import { createBrowserHistory, History } from 'history';
import { connectRouter } from 'connected-react-router';

import { routerMiddleware } from 'connected-react-router';
import accessControlMiddleware from './middleware/accessControlMiddleware';

export const history: History = createBrowserHistory();

const rootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        iam: iamReducer,
        analysis: analysisReducer,
        tracked_websites: trackedWebsitesReducer,
        notifications: notificationsReducer,
        loading_time: loadingTimeReducer,
        monitor_locations: monitorLocationsReducer,
        outage: outageReducer,
        white_label: whiteLabelReducer,
        sales: salesReducer,
        trackers: trackersReducer,
        [sitemapApi.reducerPath]: sitemapApi.reducer,
        [brokenLinksApi.reducerPath]: brokenLinksApi.reducer,
        [siteOutageApi.reducerPath]: siteOutageApi.reducer,
        [siteSpeedApi.reducerPath]: siteSpeedApi.reducer,
        [trackedKeywordsApi.reducerPath]: trackedKeywordsApi.reducer,
        [keywordResearchApi.reducerPath]: keywordResearchApi.reducer,
        [topKeywordsApi.reducerPath]: topKeywordsApi.reducer,
        [trackedWebsitesApi.reducerPath]: trackedWebsitesApi.reducer,
        [locationsAndLanguagesApi.reducerPath]: locationsAndLanguagesApi.reducer,
        [backlinksApi.reducerPath]: backlinksApi.reducer,
        [competitorDomainsApi.reducerPath]: competitorDomainsApi.reducer,
    });

export const store = configureStore({
    reducer: rootReducer(history),

    // @ts-ignore
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
            .concat(routerMiddleware(history))
            .concat(accessControlMiddleware)
            .concat(backlinksApi.middleware)
            .concat(trackedWebsitesApi.middleware)
            .concat(locationsAndLanguagesApi.middleware)
            .concat(topKeywordsApi.middleware)
            .concat(keywordResearchApi.middleware)
            .concat(trackedKeywordsApi.middleware)
            .concat(brokenLinksApi.middleware)
            .concat(sitemapApi.middleware)
            .concat(siteSpeedApi.middleware)
            .concat(siteOutageApi.middleware)
            .concat(competitorDomainsApi.middleware);
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const dispatch = store.dispatch;
