import React from 'react';
import { useNavigate } from '../../../router/hooks/useNavigate';
import { Button } from 'antd';
import { TrackedWebsite } from '../../../trackedWebsites/types';
import TrackedKeywordsCount from '../../../trackedKeywords/TrackedKeywordsCount';
import KeywordsTable from './KeywordsTable';
import KeywordsInput from './KeywordsInput';
import {
    useAddTrackedKeywordsMutation,
    useChangeKeywordsLocationAndLanguageMutation,
    useFetchTrackedKeywordsCountQuery,
} from '../../../trackedKeywords/service';
import { useUpdateTrackedWebsiteSettingsMutation } from '../../../trackedWebsites/service';
import ChangeLocationAndLanguageForm from '../../../trackedKeywords/ChangeLocationAndLanguageForm';
import { useGetMaxValuesForPlan } from '../../../sales/hooks/useGetMaxValuesForPlan';

type Props = {
    selectedWebsite: TrackedWebsite;
    setSelectedWebsite: (newSelectedWebsite: TrackedWebsite | null) => void;
};

export default function KeywordsTab({ selectedWebsite, setSelectedWebsite }: Props) {
    const navigate = useNavigate();

    const { maxKeywords } = useGetMaxValuesForPlan();
    const { data: trackedKeywordsCount, isFetching: isFetchingTrackedKeywordsCount } = useFetchTrackedKeywordsCountQuery();

    const [updateTrackedWebsiteSettings, { isLoading: isChangingLocationAndLanguageForWebsite }] = useUpdateTrackedWebsiteSettingsMutation();

    const [changeKeywordsLocationAndLanguage, { isLoading: isChangingLocationAndLanguageForKeywords }] = useChangeKeywordsLocationAndLanguageMutation();

    const [addTrackedKeywords, { isLoading: isAddingTrackedKeywords }] = useAddTrackedKeywordsMutation();

    const handleLocationChange = async (location: string, language: string) => {
        try {
            await changeKeywordsLocationAndLanguage({ domain: selectedWebsite.domain, locationName: location, languageCode: language }).unwrap();

            const updatedSettings = await updateTrackedWebsiteSettings({
                guid: selectedWebsite.guid,
                url: selectedWebsite.url,
                location_name: location,
                language_code: language,
            }).unwrap();

            setSelectedWebsite({ ...selectedWebsite, settings: { ...selectedWebsite.settings, ...updatedSettings } });
        } catch (error) {}
    };

    const handleAddedKeyword = (newKeyword: string) => {
        addTrackedKeywords({
            domain: selectedWebsite.domain,
            locationName: selectedWebsite.settings.location_name,
            languageCode: selectedWebsite.settings.language_code,
            keywords: [newKeyword],
        });
    };

    const isKeywordsInputLoading = isAddingTrackedKeywords || isFetchingTrackedKeywordsCount;
    const isChangingLocationAndLanguage = isChangingLocationAndLanguageForWebsite || isChangingLocationAndLanguageForKeywords;
    const showAddMoreInput = maxKeywords && !!trackedKeywordsCount && trackedKeywordsCount.keywords_no < maxKeywords;

    return (
        <div className="keywords-tab">
            <TrackedKeywordsCount />

            <ChangeLocationAndLanguageForm
                key={`changeDbForm_${selectedWebsite.guid}`}
                customClassName={'dashboard-settings-change-regional-db-form'}
                initialLocation={selectedWebsite.settings.location_name}
                initialLanguageCode={selectedWebsite.settings.language_code}
                onSubmit={handleLocationChange}
                isChangingLanguageAndLocation={isChangingLocationAndLanguage}
            />

            {showAddMoreInput && (
                <KeywordsInput
                    onAddedKeyword={handleAddedKeyword}
                    isLoading={isKeywordsInputLoading}
                />
            )}

            <KeywordsTable
                key={selectedWebsite.guid}
                selectedWebsite={selectedWebsite}
            />

            <Button
                className="redirect-link"
                type="link"
                onClick={() => navigate('/keyword-position')}
            >
                Check keywords overview
            </Button>
        </div>
    );
}
