import React from 'react';
import { TrackedWebsite } from '../trackedWebsites/types';
import BasicModal from '../components/shared/BasicModal/BasicModal';
import IconLabel from '../components/shared/IconLabel/IconLabel';
import ModalTitle from '../components/shared/ModalTitle/ModalTitle';
import AddTrackedKeywordsForm from './AddTrackedKeywordsForm';
import { useAddTrackedKeywordsMutation } from './service';

type Props = {
    selectedWebsite: TrackedWebsite;
};

export default function AddTrackedKeywords({ selectedWebsite }: Props) {
    const [addTrackedKeywords, { isLoading: isAddingTrackedKeywords }] = useAddTrackedKeywordsMutation();

    const handleAddTrackedKeywords = (keywords: string[]) => {
        addTrackedKeywords({
            domain: selectedWebsite.domain,
            locationName: selectedWebsite.settings.location_name,
            languageCode: selectedWebsite.settings.language_code,
            keywords: keywords,
        });
    };

    return (
        <BasicModal
            openLabel={
                <IconLabel
                    icon="plus-circle"
                    label="Add Keywords"
                />
            }
            modalTitle={
                <ModalTitle
                    title="Add keywords"
                    meta={selectedWebsite.domain}
                />
            }
            isPerformingAction={isAddingTrackedKeywords}
        >
            <AddTrackedKeywordsForm
                domain={selectedWebsite.domain}
                onSubmit={handleAddTrackedKeywords}
                isAddingTrackedKeywords={isAddingTrackedKeywords}
            />
        </BasicModal>
    );
}
