import React, { useState } from 'react';
import { Card, Icon, Input, Button, Modal, Form } from 'antd';
import ModalTitle from '../components/shared/ModalTitle/ModalTitle';
import Backlinks from './Backlinks';
import { validate } from "validate.js";

// const domainValidationRegex = /([a-z0-9]+\.)*[a-z0-9]+\.[a-z]+/;
/**

Validate string as domain:
1 - check if string is URL
1.1 - if URL, extract domain
1.2 - if not URL, add https:// as prefix and check if URL again

*/
const urlValidationRule = {
    url: {
        url: {
            message: "^Provide a valid URL"
        }
    }
};

function parseUrl(input: string) {
    const error = validate({ url: input }, urlValidationRule);
    if (!error) {
        return new URL(input);
    }
    return null;
}

function getDomain(input: string) {
    input = input.toLowerCase().trim();

    let parsedUrl = parseUrl(input);
    if (!parsedUrl) {
        parsedUrl = parseUrl(`https://${input}`);
    }

    return parsedUrl ? parsedUrl.hostname : null;
}



export default function BacklinksForm() {
    const [domain, setDomain] = useState('');
    const [error, setError] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDomain(event.target.value);
        setError('');
    };

    const onSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();

        const targetDomain = getDomain(domain);
        if (targetDomain) {
            setDomain(targetDomain);
            setError('');
            setIsModalVisible(true);
        } else {
            setError('Invalid domain');
        }
    };

    const closeModal = () => {
        setDomain('');
        setError('');
        setIsModalVisible(false);
    };

    return (
        <Card className="analysis-form-card">
            <Form
                onSubmit={onSubmit}
                className="analysis-form"
            >
                <Form.Item
                    validateStatus={error ? "error" : "validating"}
                    help={error ? "Provide a valid domain or URL" : ""}
                >
                    <Input.Group
                        className="compact-input-group"
                        compact
                        size="large"
                    >
                        <Input
                            prefix={
                                <Icon
                                    type="global"
                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                />
                            }
                            placeholder="Enter domain or URL"
                            name="domain"
                            size="large"
                            value={domain}
                            onChange={handleInputChange}
                        />
                        <Button
                            htmlType="submit"
                            type="primary"
                            size="large"
                        >
                            Get Backlinks
                        </Button>
                    </Input.Group>
                </Form.Item>
            </Form>
            <Modal
                className="backlinks-modal"
                title={
                    <ModalTitle
                        title="Backlinks for:"
                        meta={domain}
                    />
                }
                visible={isModalVisible}
                onCancel={closeModal}
                footer={null}
                centered
                destroyOnClose
            >
                <Backlinks domain={domain} />
            </Modal>
        </Card>
    );
}
