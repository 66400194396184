import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { useGetMaxValuesForPlan } from '../sales/hooks/useGetMaxValuesForPlan';
import { extractKeywords } from '../utils/keywords';
import { useFetchTrackedKeywordsCountQuery } from './service';

const { TextArea } = Input;

const initialKeywordsInputState = {
    keywordsString: '',
    validationError: '',
};

type Props = {
    domain: string;
    isAddingTrackedKeywords: boolean;
    onSubmit: (keywords: string[]) => void;
};

export default function AddTrackedKeywordsForm({ domain, isAddingTrackedKeywords, onSubmit }: Props) {
    const [keywordsInput, setKeywordsInput] = useState(initialKeywordsInputState);

    const { maxKeywords } = useGetMaxValuesForPlan();
    const { data: trackedKeywordsCount, isFetching: isFetchingTrackedKeywordsCount } = useFetchTrackedKeywordsCountQuery();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (trackedKeywordsCount) {
            const keywords = extractKeywords(keywordsInput.keywordsString);

            if (keywords.length + trackedKeywordsCount.keywords_no <= maxKeywords) {
                onSubmit(keywords);
            } else {
                setKeywordsInput({ ...keywordsInput, validationError: 'Keywords exceeded limit' });
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setKeywordsInput({ keywordsString: event.target.value, validationError: '' });
    };

    const isButtonLoading = isAddingTrackedKeywords || isFetchingTrackedKeywordsCount;

    return (
        <Form
            className="add-tracked-keywords-form"
            layout="vertical"
            onSubmit={handleSubmit}
            style={{ padding: 20 }}
        >
            <p>Add your desired keywords for {domain} here. You can add them one by one, or add multiple keywords separated by comma.</p>
            <Form.Item
                validateStatus={keywordsInput.validationError ? 'error' : 'validating'}
                help={keywordsInput.validationError}
            >
                <TextArea
                    disabled={isFetchingTrackedKeywordsCount}
                    value={keywordsInput.keywordsString}
                    onChange={handleChange}
                    placeholder="keyword1, keyword2, keyword3, etc"
                    autoSize={{ minRows: 5, maxRows: 25 }}
                />
            </Form.Item>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isButtonLoading}
                >
                    Add keywords
                </Button>
            </Form.Item>
        </Form>
    );
}
